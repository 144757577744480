import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Content from '@solid-ui-blocks/Content/Block01'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Stats from '@solid-ui-blocks/Stats/Block01'
import Strategies from '@solid-ui-blocks/Features/Block06'
import Blog from '@solid-ui-blocks/Blog/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = (props) => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  
  return (
    <Layout theme={theme} {...props} allBlockContent={allBlockContent}>
      <Seo title='Home' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='4' />
      <Companies content={content['companies']} />
      <Divider space='5' />
      <Divider space='5' />
      <FeatureOne content={content['service']} />
      <Divider space='5' />
      <Divider space='5' />
      <Stats content={content['stats']} />
      <Divider space='5' />
      <Divider space='5' />
      <Strategies content={content['strategies']} />
      <Divider space='5' />
      <Divider space='5' />
      <Blog content={content['case-studies']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.contentTwoContainer}>
        <Content content={content['consultation-cta']} />
      </Container>
      <Divider space='5' />
    </Layout>
  )
}

export const query = graphql`
  query homepageBlockContent {
    allBlockContent(
      filter: { page: { in: ["studio-website/index", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
